import styled from "styled-components";
import Modal from "react-modal";
import { RxCross2 } from "react-icons/rx";
import 'react-datepicker/dist/react-datepicker.css';

export const OrderFormModalStyledWrapper = styled.div``;

export const ModalStyled = styled(Modal)`
  .ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.25); /* Черный фон с прозрачностью 25% */
    z-index: 10001; /* Убедитесь, что это значение больше, чем у других элементов */
    box-shadow: 0px 0px 40px 30px #0000008C;
    width: 100%;
}

  .ReactModal__Content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10001; 
    max-width: 540px; 
    width: 100%;
    max-height: 750px; 
    height: 100%;
    background: white; 
    padding: 20px;
    border-radius: 8px;
  }
`;

export const ModalContent = styled.div`
  position: relative;
  background: white;
  padding: 30px 50px 40px 50px;
  border-radius: 8px;
  max-width: 780px;
  width: 100%;
  max-height: 905px; 
  height: 100%;
  margin: auto;
  background: #E9D59C;
  overflow: auto;  /* Разрешаем прокрутку содержимого */
  box-shadow: 0px 0px 40px 30px #0000008C;

  @media screen and (max-width: 768px) {
    width: 90%;
    padding: 20px 30px 40px 30px;


  }
`;

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  
.PhoneInput {
  background-color: white;
  padding: 13px 20px;
  border-radius: 50px;
  font-family: DM Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 23.44px;
  margin: 5px 0;

  @media screen and (max-width: 768px) {
    margin: 2px 0;
    padding: 7px 20px;

    }
  }

.PhoneInputInput {
    border: none;
    outline: none;
    background: transparent;
    font-size: 23px;
    color: black; /* Цвет текста */
    padding-left: 10px;
    width: 100%;
    text-align: center;
    padding-right: 60px;
    font-family: DM Sans;
    font-weight: 500;

    @media screen and (max-width: 768px) {
      font-size: 14px;

    }
  }

  .PhoneInputCountry {
    display: flex;
    align-items: center;
    padding-left: 10px;
  }

  .PhoneInputCountrySelect {
    border: none;
    background: transparent;
    outline: none;
    font-size: 18px;
    cursor: pointer;
  }

  .custom-timepicker{
    width: 100%;
  padding: 10px;
  margin: 5px 0;
  border-radius: 25px;
  border: none;
  font-size: 16px;
  text-align: center;
  outline: none;
  font-family: DM Sans;
  font-size: 23px;
  font-weight: 500;
  line-height: 29.95px;

    input[type="time"]:before{
      content: attr(placeholder);
    }

    ::placeholder {
    color: #999;
    font-style: italic;
  }
  @media screen and (max-width: 768px) {
    padding: 7px;
    margin: 2px 0;
    font-family: DM Sans;
    font-size: 14px;
     font-weight: 500;
    line-height: 18.23px;
    text-align: center;


  }
}

.custom-datepicker{
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border-radius: 25px;
  border: none;
  font-size: 16px;
  text-align: center;
  outline: none;
  font-family: DM Sans;
  font-size: 23px;
  font-weight: 500;
  line-height: 29.95px;

    input[type="time"]:before{
      content: attr(placeholder);
    }

    ::placeholder {
    color: #999;
    font-style: italic;
  }
  @media screen and (max-width: 768px) {
    padding: 7px;
    margin: 2px 0;
    font-family: DM Sans;
    font-size: 14px;
     font-weight: 500;
    line-height: 18.23px;
    text-align: center;


  }
}
`;

export const OptionName = styled.div`
font-family: Rockwell Std;
font-size: 40px;
font-weight: 700;
line-height: 46.97px;
text-align: center;

@media screen and (max-width: 768px) {
  font-size: 20px;
  font-weight: 700;
  line-height: 23.49px;
  text-align: center;
}
`;


export const RxCross2Custom = styled(RxCross2)`
font-size: 34px;
color: black;
cursor: pointer;
transition: color 0.3s ease, text-shadow 0.3s ease;

@media (hover:hover){

&:hover{
      color: red;
  }
}

@media screen and (max-width: 768px){
    font-size: 26px;

    @media (hover:none){
    &:active{
      color: black;
      text-shadow: 0px 5px 5px 0px #00000040;
    }
  }
}

`;

export const TitleBlock = styled.div`
display: flex;
flex-direction: row;
gap:10px;
justify-content: space-between;
margin-bottom: 15px;
align-items: center;
`;



export const AddOnsOderButton = styled.button`
display: flex;
flex-direction: column;
width: 100%;
background: #4E94B2;
border-radius: 50px;
border: none;
height: 44px;
font-family: DM Sans;
font-size: 20px;
font-weight: 700;
line-height: 26.04px;
justify-content: center;
align-items: center;
cursor: pointer;
transition: background-color 0.3s ease; 
color: white;
box-shadow: 0px 4px 11px 0px #00000059;

  &.back-button{
    background: #F0823D;

    &:hover{
    background:#CD6F34;

}
  }

&:hover{
    background: #3D7F9F;

}

@media screen and (max-width: 768px) {
font-size: 14px;
font-weight: 700;
line-height: 18.23px;
text-align: center;

}

@media (hover:none){
    &:active{
      color: black;
      text-shadow: 0px 5px 5px 0px #00000040;
    }}
`;


export const ButtonContainer = styled.div`
display: flex;
flex-direction: column;
gap:14px;
padding: 28px 0 0 0;

`;

export const StyledInput = styled.input`
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border-radius: 25px;
  border: none;
  font-size: 16px;
  text-align: center;
  outline: none;
  font-family: DM Sans;
  font-size: 23px;
  font-weight: 500;
  line-height: 29.95px;

    input[type="time"]:before{
      content: attr(placeholder);
    }

    ::placeholder {
    color: #999;
    font-style: italic;
  }
  @media screen and (max-width: 768px) {
    padding: 7px;
    margin: 3px 0;
    font-family: DM Sans;
    font-size: 14px;
     font-weight: 500;
    line-height: 18.23px;
    text-align: center;


}

&.number{
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

}
`;

export const StyledTextArea = styled.textarea`
  width: 100%;
  padding: 15px;
  margin: 10px 0;
  border-radius: 25px;
  border: none;
  font-size: 16px;
  text-align: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  outline: none;  /* Убирает обводку при фокусе */
  resize: none;
  font-family: DM Sans;
font-size: 23px;
font-weight: 500;
line-height: 29.95px;
text-align: center;

  &:focus {
    outline: none;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  }

  @media screen and (max-width: 768px) {
    font-family: DM Sans;
    font-size: 14px;
    font-weight: 500;
    line-height: 18.23px;
    text-align: center;
    padding: 7px;
    margin: 2px 0;
  }
`;

export const SelectedAdd = styled.div`
font-family: DM Sans;
font-size: 23px;
font-weight: 500;
line-height: 29.95px;
margin-bottom: 5px;

@media screen and (max-width: 768px) {
  font-family: DM Sans;
font-size: 12px;
font-weight: 400;
line-height: 15.62px;
text-align: left;
}

`;


export const InputWrapper = styled.div`
  position: relative;
  display: inline-block;
  
  &::before {
    content: attr(data-placeholder);
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #999;
    pointer-events: none;
  }

  input:focus + &::before,
  input:not(:placeholder-shown) + &::before {
    display: none;
  }
`;

export const ButtonClose = styled.button`
  background-color: #fff;
  border: none;
  border-radius: 50px;
  padding: 10px 20px;
  margin: 10px;
  color: #000;
  cursor: pointer;
  font-weight: bold;
  width: 60%;
  box-shadow: 0px 4px 4px 0px #00000040;
  font-family: DM Sans;
  font-size: 18px;
  font-weight: 500;
  line-height: 23.44px;

  &:hover:not(:focus) {
  background-color: #f2f2f2;
  }
`;