import React, { ReactElement } from "react";

import { Button, GalleryBlockContainer, GalleryImage, GalleryImageContainer, GalleryImageContainerMobile, ImageBigContainer, ImageSmallContiner, PhotoContainer, PhotoStyledWrapper, Title } from "./Photo.styled";

interface PhotoProps {
  title: string;
  photo: string[];
  mobilePhoto: string[];
  isFollow?: boolean;
}

export const Photo = (props: PhotoProps): ReactElement => {
  const { title, photo, mobilePhoto, isFollow = true } = props;
  return (
    <PhotoStyledWrapper>
      <PhotoContainer>
        <Title>{title}</Title>
        <GalleryImageContainer>

          <GalleryBlockContainer>
            <ImageBigContainer>
              <GalleryImage className="top-left" src={photo[0]} />
            </ImageBigContainer>
            <ImageSmallContiner>
              <GalleryImage className="bottom-left" src={photo[1]} />
            </ImageSmallContiner>
          </GalleryBlockContainer>

          <GalleryBlockContainer>
            <ImageSmallContiner>
              <GalleryImage src={photo[2]} />
            </ImageSmallContiner>
            <ImageBigContainer>
              <GalleryImage src={photo[3]} />
            </ImageBigContainer>
          </GalleryBlockContainer>

          <GalleryBlockContainer className="third-block">
            <ImageBigContainer>
              <GalleryImage className="top-right" src={photo[4]} />
            </ImageBigContainer>
            <ImageSmallContiner>
              <GalleryImage className="bottom-right" src={photo[5]} />
            </ImageSmallContiner>
          </GalleryBlockContainer>

        </GalleryImageContainer>
        <GalleryImageContainerMobile>

          <GalleryBlockContainer>
            <ImageBigContainer>
              <GalleryImage className="top-left" src={mobilePhoto[0]} />
            </ImageBigContainer>
            <ImageSmallContiner>
              <GalleryImage className="bottom-left" src={mobilePhoto[1]} />
            </ImageSmallContiner>
          </GalleryBlockContainer>

          <GalleryBlockContainer>
            <ImageSmallContiner>
              <GalleryImage className="top-right" src={mobilePhoto[2]} />
            </ImageSmallContiner>
            <ImageBigContainer>
              <GalleryImage className="bottom-right" src={mobilePhoto[3]} />
            </ImageBigContainer>
          </GalleryBlockContainer>

        </GalleryImageContainerMobile>
        {isFollow && (
          <div style={{ display: "flex", justifyContent: "center", paddingTop: "10px" }}>
            <Button>FOLLOW US</Button>
          </div>
        )}
      </PhotoContainer>
    </PhotoStyledWrapper>
  );
};
