import React, { ReactElement } from "react";

import { FooterContainer, FooterItems, FooterLink, FooterStyledWrapper, MenuContainer, MenuTitle, SocialIcon, SocialLogoContainer, SocialLogoWrapper } from "./Footer.styled";
import { Logo } from "../../shared-components/Logo/Logo";
import { SlSocialInstagram } from "react-icons/sl";
import { SlSocialFacebook } from "react-icons/sl";
import { SiLinkedin } from "react-icons/si";
interface FooterProps { }

export const Footer = (props: FooterProps): ReactElement => {
  const { } = props;

  return (
    <FooterStyledWrapper>
      <FooterContainer>
        <FooterItems>
          <Logo />
          <SocialLogoWrapper>
            <SocialLogoContainer>
              <SocialIcon as={SlSocialInstagram} />
            </SocialLogoContainer>
            <SocialLogoContainer>
              <SocialIcon as={SlSocialFacebook} />
            </SocialLogoContainer>
            <SocialLogoContainer>
              <SocialIcon as={SiLinkedin} />
            </SocialLogoContainer>
          </SocialLogoWrapper>
          <MenuContainer>
            <MenuTitle>Menu</MenuTitle>
            <FooterLink to={"/services"}>
              Services
            </FooterLink>
            <FooterLink to={"/about"}>
              About
            </FooterLink>
            <FooterLink to={"/photo"}>
              Photo
            </FooterLink>
          </MenuContainer>
        </FooterItems>
      </FooterContainer>
    </FooterStyledWrapper>
  );
};
