export const what_is_MOCK: any[] = [
  {
    id: "1",
    title: "What is this?",
    text: "A picnic is a popular form of recreation, consisting of eating food outdoors on a spread mator in a gazebo.",
    imageUrl: "/what-is/what-is-1.png",
    imgUrlMobile:"/what-is/what-is-1-mobile.png"
   },
  {
    id: "2",
    title: "How it works?",
    text: "A picnic is a popular form of recreation, consisting of eating food outdoors on a spread mat or in a gazebo. A picnic is a popular form of recreation, consisting of eating food outdoors on a spread mat or in a gazebo.",
    imageUrl: "/what-is/what-is-2.png",
    imgUrlMobile:"/what-is/what-is-2-mobile.png"

  },
  {
    id: "3",
    title: "Where does this happen?",
    text: "A picnic is a popular form of recreation, consisting of eating food outdoors on a spread mat or in a gazebo. A picnic is a popular form of recreation, consisting.",
    imageUrl: "/what-is/what-is-3.png",
    imgUrlMobile:"/what-is/what-is-3-mobile.png"

  },
]