import React, { useEffect, useState } from "react";
import { Logo } from "../Logo/Logo";
import { ImageFirstContainer, NavigationBackgroundImageContainer, NavigationContentContainer, NavigationInfoContainer, NavigationStyledWrapper, TextDetails, Title, Text, TitleContainer, ImageSecondContainer, MainImageContainer, MainImage, GroupImagesContainer, SecondImageGroup, FirstImageGroup, OrderButton, TextOrder, TextOrderContainer, NavigationContainer, NavigationBackgroundImageContainerMobile, NavigationContentContainerMobile, ImageSecondContainerMobile, NavigationInfoContainerMobile, TitleContainerMobile, MainImageContainerMobile, MainImageMobile, OrderButtonMobile, SecondImageMobile, FirstImageMobile } from "./Navigation.styled";
import { Header } from "../../composit-components/Header/Header";
import { IoMdArrowDropright } from "react-icons/io";
import { HeaderMobile } from "../../composit-components/HeaderMobile/HeaderMobile";

export const Navigation = () => {
  const [height, setHeight] = useState(window.innerHeight);
  const [booleanTest, setBooleanTest] = useState(false);

  useEffect(() => {
    const handleResize = () => setHeight(window.innerHeight);
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <NavigationStyledWrapper>
      <NavigationContainer>
        <NavigationBackgroundImageContainer height={928}>
          <NavigationContentContainer>
            <Header />
            <ImageFirstContainer src="/header-image/first-image.png">
            </ImageFirstContainer>
            <ImageSecondContainer src="/header-image/second-image.png">
            </ImageSecondContainer>

            <NavigationInfoContainer>
              <TitleContainer>
                <Title>DREAM PICNIC</Title>
                <Text>Organizing outdoor picnics</Text>
                <TextDetails>A picnic is a popular form of recreation, consisting of eating food outdoors on a spread mat or in a gazebo.</TextDetails>
              </TitleContainer>
              <MainImageContainer>
                <MainImage src="/header-image/third-image.png" />
              </MainImageContainer>

              <GroupImagesContainer>
                <FirstImageGroup src="/header-image/group-first-image.png" />
                <SecondImageGroup src="/header-image/group-second-image.png" />
                <OrderButton to={"/services"}>
                  <TextOrderContainer>
                    <TextOrder>
                      <p>
                        ORDER
                      </p>
                      <p>
                        PICNIC
                      </p>
                    </TextOrder>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <IoMdArrowDropright size={28} />
                    </div>
                  </TextOrderContainer>
                </OrderButton>

              </GroupImagesContainer>
            </NavigationInfoContainer>
          </NavigationContentContainer>
        </NavigationBackgroundImageContainer>

        <NavigationBackgroundImageContainerMobile height={420}>
          <NavigationContentContainerMobile>
            <HeaderMobile />
            <ImageSecondContainerMobile src="/header-image/second-image.png">
            </ImageSecondContainerMobile>

            <NavigationInfoContainerMobile>
              <TitleContainerMobile>
                <Title>DREAM PICNIC</Title>
                <Text>Organizing outdoor picnics</Text>
                <TextDetails>A picnic is a popular form of recreation, consisting of eating food outdoors on a spread mat or in a gazebo.</TextDetails>
              </TitleContainerMobile>
               <MainImageContainerMobile>
                <MainImageMobile src="/header-image/third-image.png" />
              </MainImageContainerMobile>
              <OrderButtonMobile to={"/services"}>
                  <TextOrderContainer>
                    <TextOrder>
                      <p>
                        ORDER
                      </p>
                      <p>
                        PICNIC
                      </p>
                    </TextOrder>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <IoMdArrowDropright size={24} />
                    </div>
                  </TextOrderContainer>
                </OrderButtonMobile>
                <SecondImageMobile src="/header-image/group-second-image.png" />
                <FirstImageMobile src="/header-image/group-first-image.png" />

{/*
              <GroupImagesContainer>
                

              </GroupImagesContainer> */}
            </NavigationInfoContainerMobile>
          </NavigationContentContainerMobile>
        </NavigationBackgroundImageContainerMobile>



      </NavigationContainer>
    </NavigationStyledWrapper >
  );
};
