import React, { ReactElement, useState } from "react";
import { AddOnsOderButton, ButtonClose, ButtonContainer, DataContainer, ModalContent, ModalStyled, OptionName, SelectedAdd, StyledInput, StyledTextArea, TitleBlock } from "./OrderFormModal.styled";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { aboutUsApi } from "../../../api/requests/about-us.api";
import DatePicker from "react-datepicker";
import { format } from 'date-fns';

interface OrderFormModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onBack: () => void;
  item: any;
  selectedAddons: string[];
}

interface FormData {
  name: string;
  email: string;
  phone: string;
  numberOfGuests: string;
  preferredDate: Date | null;  // Тип: Date или null
  picnicStartTime: Date | null; // Тип: Date или null
  heardAboutUs: string;
  additionalInfo: string;
}

export const OrderFormModal = (props: OrderFormModalProps): ReactElement => {
  const { isOpen, onRequestClose, onBack, item, selectedAddons } = props;
  const [formData, setFormData] = useState<FormData>({
    name: "",
    email: "",
    phone: "",
    numberOfGuests: "",
    preferredDate: null, // Оставляем значение пустым
    picnicStartTime: null, // Оставляем значение пустым
    heardAboutUs: "",
    additionalInfo: ""
  });

  const [isNotificationModalOpen, setNotificationModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // Добавлено состояние для блокировки кнопки

  const [preferredDateType, setPreferredDateType] = useState("text");
  const [picnicStartTimeType, setPicnicStartTimeType] = useState("text");
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    if (name === "numberOfGuests" && value.length > 5) {
      return;
    }

    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));

  };

  const handleOrderClick = async () => {
    if (isSubmitting) return; // Защита от повторного клика

    setIsSubmitting(true); // Блокируем кнопку
    try {
      const formattedPreferredDate = formData.preferredDate
        ? format(formData.preferredDate, 'dd.MM.yyyy')
        : null;

      const formattedPicnicStartTime = formData.picnicStartTime
        ? format(formData.picnicStartTime, 'HH:mm')
        : null;

      const orderDetails = {
        ...formData,
        preferredDate: formattedPreferredDate?.replaceAll(".", "-"),
        picnicStartTime: formattedPicnicStartTime,
        item,
        selectedAddons
      };

      await aboutUsApi.selectedOrder(orderDetails); // Отправляем данные
      console.log(orderDetails);
      setNotificationModalOpen(true);
      onRequestClose();
    } catch (error) {
      console.error("Ошибка при отправке заказа", error);
    } finally {
      setIsSubmitting(false); // Разблокируем кнопку после завершения
    }
  };

  const handleBlurDate = () => {
    setPreferredDateType("text");
  };

  const handleFocusDate = (event: React.FocusEvent<HTMLInputElement>) => {
    setPreferredDateType("date");
    setTimeout(() => {
      event.target.click(); // Программно вызываем клик после изменения типа
    }, 0);

  };

  const handleFocusTime = (event: React.FocusEvent<HTMLInputElement>) => {
    setPicnicStartTimeType("time");
    setTimeout(() => {
      event.target.click(); // Программно вызываем клик после изменения типа
    }, 0);

  };

  const handleBlurTime = () => {
    setPicnicStartTimeType("text");
  };

  const handleDateChange = (date: Date | null) => {
    setFormData(prevData => ({
      ...prevData,
      preferredDate: date // Можно установить как Date, так и null
    }));

  };

  const handleTimeChange = (time: Date | null) => {
    setFormData(prevData => ({
      ...prevData,
      picnicStartTime: time // Можно установить как Date, так и null
    }));

  };

  return (
    <>
      <ModalStyled
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        className="modal-check"
        style={{
          content: {
            zIndex: 10001,
            width: "100%"
          },

          overlay: {
            zIndex: 10001,
            backgroundColor: 'rgba(0, 0, 0, 0.25)',
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "auto",
            width: "100%"
          },

        }}

      >
        <ModalContent>
          <TitleBlock>
            <OptionName>{item.name}</OptionName>
          </TitleBlock>
          <SelectedAdd>Selected add-ons: {selectedAddons.length === 0 ? "none" : selectedAddons.join(", ")}</SelectedAdd>
          <DataContainer>
            <StyledInput
              type="text"
              placeholder="Name, Last name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              maxLength={50}
            />
            <StyledInput
              type="email"
              placeholder="E-mail"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              maxLength={50}
            />
            <PhoneInput
              defaultCountry="US"
              value={formData.phone}
              onChange={(phone) => setFormData(prevState => ({
                ...prevState,
                phone: phone || ""
              }))}
              placeholder="Enter the phone number"
            />
            <StyledInput
              type="number"
              placeholder="Number of guests"
              name="numberOfGuests"
              className="number"
              value={formData.numberOfGuests}
              onChange={handleInputChange}
              min="0"
              max="300"

            />

            <DatePicker
              selected={formData.preferredDate} // Оставляем как есть
              onChange={handleDateChange}
              dateFormat="dd.MM.yyyy"
              showYearDropdown // Добавляем выпадающий список для года
              scrollableYearDropdown
              yearDropdownItemNumber={15} // Количество отображаемых лет
              showMonthDropdown
              placeholderText="Select date" // Указываем текст плейсхолдера
              className="custom-datepicker"
            />

            <DatePicker
              selected={formData.picnicStartTime} // Оставляем как есть
              onChange={handleTimeChange}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="HH:mm"
              placeholderText="Select time" // Указываем текст плейсхолдера
              className="custom-timepicker"
            />

            <StyledInput
              type="text"
              placeholder="How did you hear about us?"
              name="heardAboutUs"
              value={formData.heardAboutUs}
              onChange={handleInputChange}
              maxLength={150}
            />

            <StyledTextArea
              placeholder="Share more information about your wishes"
              name="additionalInfo"
              value={formData.additionalInfo}
              onChange={handleInputChange}
              maxLength={300}
            ></StyledTextArea>

          </DataContainer>
          <ButtonContainer>
            <AddOnsOderButton onClick={handleOrderClick} disabled={isSubmitting}>
              {isSubmitting ? "Submitting..." : "ORDER"}
            </AddOnsOderButton>
            <AddOnsOderButton className="back-button" onClick={onBack}>BACK TO ADD-ONS</AddOnsOderButton>
          </ButtonContainer>
        </ModalContent>
      </ModalStyled>
      <ModalStyled
        isOpen={isNotificationModalOpen}
        onRequestClose={() => setNotificationModalOpen(false)}
        className="modal-notification"
        style={{
          content: {
            zIndex: 10001,
            width: "300px",
            textAlign: "center"
          },
          overlay: {
            zIndex: 10001,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      >
        <ModalContent>
          <h2>Order placed successfully!</h2>
          <ButtonClose onClick={() => setNotificationModalOpen(false)}>Close</ButtonClose>
        </ModalContent>
      </ModalStyled>
    </>
  );
};