import React, { ReactElement, useEffect, useState } from "react";
import { Button, ButtonContainer, Input, LeftImageWhatOrder, MessageContainer, PageContainer, PageStyledWrapper, PhotoContainer, QuickOrderContainer, ServiceBlock, ServiceContent, ServiceItem, ServicesContainer, ServiceTitle, StyledTitle, VideoBlock, VideoContainer, VideoContent, WhatFoodOrder, WhatFoodOrderMobile, WhatIsContainer, WhatIsContent, WhatOrderContent, WhatOrderImageContainer, WhatOrderImageContainerMobile, WhatOrderImageMobile, WhatOrderImageSectionMobile, WhatOrderInfo, WhatOrderInfoContainer, WhatOrderInfoContainerMobile, WhatOrderInfoMobile, WhatOrderMainImage, WhatOrderMobileContainer, WhatOrderSmallFirstImage, WhatOrderSmallImageContainer, WhatOrderSmallSecondImage } from "./Page.styled";
import { WhatIs } from "../../components/shared-components/WhatIs/WhatIs";
import { what_is_MOCK } from "../../dbMock/gardenItems";
import { services_MOCK } from "../../dbMock/services";
import { Service } from "../../components/shared-components/Service/Service";
import { Photo } from "../../components/shared-components/Photo/Photo";
import { instagram_image_MOCK } from "../../dbMock/instagram";
import { mobile_photo_image_MOCK } from "../../dbMock/mobilePhoto";
import { VideoCarousel } from "../../components/shared-components/VideoCarousel/VideoCarousel";
import { aboutUsApi } from "../../api/requests/about-us.api";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

export const Page = (): ReactElement => {
  const [phone, setPhone] = useState<string | undefined>(undefined);
  const [error, setError] = useState<string | null>(null);
  const [visibleItems, setVisibleItems] = useState<boolean[]>([]);
  const [clickCount, setClickCount] = useState<number>(0);
  const [message, setMessage] = useState<string | null>(null); // Для отображения сообщения
  const [isSubmitting, setIsSubmitting] = useState(false); // новое состояние для блокировки кнопки



  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = Number(entry.target.getAttribute("data-index"));
            setVisibleItems((prevVisibleItems) => {
              const updatedVisibleItems = [...prevVisibleItems];
              updatedVisibleItems[index] = true;
              return updatedVisibleItems;
            });
          }
        });
      },
      {
        rootMargin: "90px 0px 90px 0px", 
        threshold: 0.5,
      }
    );



    const elements = document.querySelectorAll(".service-item");
    elements.forEach((element) => {
      observer.observe(element);
    });

    return () => {
      elements.forEach((element) => {
        observer.unobserve(element);
      });
    };
  }, []);

  useEffect(() => {
    const storedClicks = localStorage.getItem("clickCount");
    const storedDate = localStorage.getItem("clickDate");

    const currentDate = new Date().toLocaleDateString();

    if (storedDate === currentDate && storedClicks) {
      setClickCount(Number(storedClicks));
    } else {
      localStorage.setItem("clickCount", "0");
      localStorage.setItem("clickDate", currentDate);
    }
  }, []);


  const handleClick = async () => {
    if (!phone || phone.length < 10) {
      setError("Enter the correct phone number.");
      setMessage("Phone number is too short!");
      clearMessageAfterTimeout();
      return;
    }

    if (clickCount >= 7) {
      setMessage("You have reached the limit of 7 orders for today.");
      return;
    }

    setIsSubmitting(true); // блокируем кнопку

    try {
      const newClickCount = clickCount + 1;
      setClickCount(newClickCount);
      localStorage.setItem("clickCount", newClickCount.toString());
      setMessage("Quick order sent successfully!");

      await aboutUsApi.quickOrder(phone); // ждем ответа от API
    } catch (error) {
      setMessage("Failed to send quick order. Try again later.");
    } finally {
      setIsSubmitting(false); // разблокируем кнопку после завершения
      setError(null);
      clearMessageAfterTimeout();
    }
  };
  const clearMessageAfterTimeout = () => {
    setTimeout(() => setMessage(''), 3000);
  };

  return <PageStyledWrapper>
    <PageContainer>
      <WhatIsContent>
        <StyledTitle>What is this ?</StyledTitle>
        <WhatIsContainer>
          {what_is_MOCK.map((item: any) => {
            return <WhatIs key={item.id}
              id={item.id}
              imgUrl={item.imageUrl}
              imgUrlMobile={item.imgUrlMobile}
              title={item.title}
              text={item.text} />
          })}
        </WhatIsContainer>
      </WhatIsContent>
      <ServicesContainer >
        <ServiceContent>
          <ServiceTitle>Our services</ServiceTitle>
          <ServiceBlock>
            {services_MOCK.map((item: any, index: number) => {
              return <ServiceItem
                key={index}
                data-index={index}
                className="service-item"
                isVisible={visibleItems[index] || false}
              >
                <Service key={item.id} index={index} item={item} href={"/services"} isFull={false} />
              </ServiceItem>

            })}
          </ServiceBlock>
        </ServiceContent>
      </ServicesContainer>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <QuickOrderContainer>
          <ButtonContainer>
            <PhoneInput
              defaultCountry="US"
              value={phone}
              onChange={setPhone}
              placeholder="Enter the phone number"
              countrySelectProps={{ className: 'PhoneInputCountrySelect' }}

            />
            <Button onClick={handleClick} disabled={isSubmitting || clickCount >= 7}>
              {isSubmitting ? "Submitting..." : "QUICK ORDER"}
            </Button>
          </ButtonContainer>
          <MessageContainer>
            {message && <span>{message}</span>}
          </MessageContainer>
        </QuickOrderContainer>
      </div>
      <VideoCarousel />

      <WhatFoodOrder>
        <StyledTitle>What food can you order</StyledTitle>
        <LeftImageWhatOrder src="/what-food-order/image-left.png" />
        <WhatOrderInfoContainer>
          <WhatOrderContent>
            <WhatOrderImageContainer>
              <WhatOrderSmallImageContainer>
                <WhatOrderSmallFirstImage src="/what-food-order/image-small-1.png" />
                <WhatOrderSmallSecondImage src="/what-food-order/image-small-2.png" />
              </WhatOrderSmallImageContainer>
              <WhatOrderMainImage src="/what-food-order/image-main.png" />
            </WhatOrderImageContainer>
            <WhatOrderInfo>
              <p>A picnic is a popular form of recreation, consisting of eating food outdoors on a spread mat or in a gazebo.
                A picnic is a popular form of recreation, consisting of eating food outdoors on a spread mat or in a gazebo. A picnic is a popular form of recreation, A picnic is a popular form of recreation, consisting of eating food outdoors on a spread mat or in a gazebo.</p>
              <p>A picnic is a popular form of recreation, consisting of eating food outdoors on a spread mat or in a gazebo. A picnic is a popular form of recreation, consisting of eating food outdoors on a spread mat or in a gazebo.consisting of eating food outdoors on a spread mat or in a gazebo.</p>
            </WhatOrderInfo>
          </WhatOrderContent>
        </WhatOrderInfoContainer>
      </WhatFoodOrder>

      <WhatFoodOrderMobile>
        <StyledTitle>What food can you order</StyledTitle>
        <WhatOrderInfoContainerMobile>
          <WhatOrderImageContainerMobile>
            <WhatOrderImageSectionMobile>
              <WhatOrderMobileContainer>
                <WhatOrderImageMobile src="/what-food-order/image-mobile-1.png" toTop={0} />
              </WhatOrderMobileContainer>
              <WhatOrderMobileContainer>
                <WhatOrderImageMobile src="/what-food-order/image-mobile-2.png" toTop={25} />
              </WhatOrderMobileContainer>
              <WhatOrderMobileContainer>
                <WhatOrderImageMobile src="/what-food-order/image-mobile-3.png" toTop={50} />
              </WhatOrderMobileContainer>
            </WhatOrderImageSectionMobile>
            <WhatOrderImageSectionMobile>
              <WhatOrderImageMobile src="/what-food-order/image-mobile-4.png" toTop={0} />
              <WhatOrderImageMobile src="/what-food-order/image-mobile-5.png" toTop={25} />
              <WhatOrderImageMobile src="/what-food-order/image-mobile-6.png" toTop={50} />
            </WhatOrderImageSectionMobile>
          </WhatOrderImageContainerMobile>
          <WhatOrderInfoMobile>
            <p>A picnic is a popular form of recreation, consisting of eating food outdoors on a spread mat or in a gazebo.
              A picnic is a popular form of recreation, consisting of eating food outdoors on a spread mat or in a gazebo. A picnic is a popular form of recreation, A picnic is a popular form of recreation, consisting of eating food outdoors on a spread mat or in a gazebo.</p>
            <p>A picnic is a popular form of recreation, consisting of eating food outdoors on a spread mat or in a gazebo. A picnic is a popular form of recreation, consisting of eating food outdoors on a spread mat or in a gazebo.consisting of eating food outdoors on a spread mat or in a gazebo.</p>
          </WhatOrderInfoMobile>
        </WhatOrderInfoContainerMobile>
      </WhatFoodOrderMobile>


      <PhotoContainer>
        <Photo title={"Instagram"} photo={instagram_image_MOCK} mobilePhoto={mobile_photo_image_MOCK} />
      </PhotoContainer>

    </PageContainer>
  </PageStyledWrapper>;
};
