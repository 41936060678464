import React, { ReactElement } from "react";
import Slider from "react-slick";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

import { VideoBlock, VideoCarouselStyledWrapper, VideoContent, VideoContentMobile, VideoDecstop, VideoMobile } from "./VideoCarousel.styled";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
interface VideoCarouselProps { }

export const VideoCarousel = (props: VideoCarouselProps): ReactElement => {
  const { } = props;
  const settings = {
    dots: true, // Включаем точки
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true, // Включаем стрелки

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  return (
    <VideoCarouselStyledWrapper>
      <VideoDecstop>
        <Slider {...settings} >
          <div style={{ width: "100%" }}>
            <VideoContent src="/video/video-1.mp4"        
              autoPlay
              muted
              loop
              playsInline
              preload="auto"
              controls={false}></VideoContent>
          </div>
          <div style={{ width: "100%" }}>
            <VideoContent src="/video/test.mp4"        
              autoPlay
              muted
              loop
              playsInline
              preload="auto"
              controls={false}></VideoContent>
          </div>
          <div style={{ width: "100%" }}>
            <VideoContent src="/video/video-3.mp4"            
              autoPlay
              muted
              loop
              playsInline
              preload="auto"
              controls={false}></VideoContent>
          </div>
        </Slider>
      </VideoDecstop>
      <VideoMobile>
        <Slider {...settings}>
          <div>
            <VideoContentMobile
              src="/video/video-mobile-1.mp4"
              autoPlay
              muted
              loop
              playsInline
              preload="auto"
              controls={false}
            />
          </div>
          <div>
          <VideoContentMobile
              src="/video/video-mobile-2.mp4"
              autoPlay
              muted
              loop
              playsInline
              preload="auto"
              controls={false}
            />          </div>
          <div>
          <VideoContentMobile
              src="/video/video-mobile-3.mp4"
              autoPlay
              muted
              loop
              playsInline
              preload="auto"
              controls={false}
            />          </div>
        </Slider>
      </VideoMobile>
    </VideoCarouselStyledWrapper>
  );
};
