export const services_MOCK: any[] = [
  {
    id: "1",
    name: "Dream luxury breakfast picnic",
    description: [
      "Begin your day with an unforgettable experience in one of Hawaii’s most secret and stunning locations. Our Luxury Breakfast Picnic takes you to a secluded spot where the island’s natural beauty surrounds you completely. Picture yourself in a private paradise, with the soft morning light, breathtaking ocean views, and the gentle rustle of palm trees. This isn’t just a meal—it’s a chance to savor a moment of pure luxury and tranquility. Treat yourself to a morning where every detail is crafte to perfection, offering an unparalleled start to your day in paradise."
    ],
    amenties: [
      "Picnic for 2 , time 2,5 hrs",
      "Boho picnic table",
      "Aesthetic decor (flowers, pillows , candles)",
      "Fruit plater, cheese plater, croissants",
      "Sparkling water, juice, coffee, tea (optional)",
      "Fruit plate, croissants",
    ],
    startingAt: "399$",
    image1Url: "/services/breakfast/image1.png",
    image2Url: "/services/breakfast/image2.png",
    image3Url: "/services/breakfast/image3.png",
    imageMainUrl: "/services/breakfast/imageMain.png"

  },
  {
    id: "2",
    name: "Dream proposal picnic",
    description: [
      "Imagine a dream proposal on the golden sands of Hawaii, where the turquoise ocean meets the horizon, and the soft breeze carries the scent of the sea. As the sun sets, casting a warm, golden glow, you arrive at a secluded spot, beautifully arranged with cozy blankets, pillows, and a table adorned with flowers.",
      "The sound of waves gently lapping the shore provides a soothing backdrop as you and your partner relax, enjoying the intimate atmosphere. Candles flicker softly as the stars begin to twinkle above, adding to the magic of the evening.",
      "At the perfect moment, with the breathtaking backdrop of the Hawaiian sunset, you get down on one knee and ask the most important question of your life. It's a moment filled with love, joy, and the promise of forever—a proposal as unforgettable as the paradise surrounding you.",
      "Create your perfect proposal with us in Hawaii, where every detail is designed to make your dream moment come true."
    ],
    amenties: [
      "Picnic for 2 , time 2,5 hrs",
      "Boho picnic table",
      "Merry Me sign",
      "Floral arrangements",
      "Aesthetic decor (flowers, pillows , candles)",
      "Fruit plater, cheese plater, croissants",
      "Sparkling water, juice, coffee, tea (optional)",
      "Fruit plater, cheese plater",
    ],
    startingAt: "499$",
    image1Url: "/services/proposal/image1.png",
    image2Url: "/services/proposal/image2.png",
    image3Url: "/services/proposal/image3.png",
    imageMainUrl: "/services/proposal/imageMain.png"

  },
  {
    id: "3",
    name: "Dream family party ",
    description: [
      "Experience the joy of togetherness with our Dream Family Picnic, where every detail is thoughtfully designed to create cherished memories. Picture your family gathered in a picturesque setting, surrounded by nature’s beauty, with cozy blankets, comfortable seating, and a beautifully arranged table.",
      "This picnic is all about enjoying quality time with your loved ones. We take care of everything, so you can focus on what matters most—making moments that will last a lifetime.",
      "Book your Dream Family Picnic today and create a magical experience your family will never forget."
    ],
    amenties: [
      "Picnic for 4-6 , time 2,5-3 hrs",
      "Boho picnic table",
      "Floral arrangements",
      "Aesthetic decor (flowers, pillows , candles)",
      "Speaker",
      "Sparkling water, juice, coffee, tea (optional)",
      "Fruit plate, cheese plate, custom charcuterie",
    ],
    startingAt: "699$",
    image1Url: "/services/family/image1.png",
    image2Url: "/services/family/image2.png",
    image3Url: "/services/family/image3.png",
    imageMainUrl: "/services/family/imageMain.png"
  },
  {
    id: "4",
    name: "Dream birthday picnic ",
    description: [
      "Celebrate your special day with a Dream Birthday Picnic, where every moment is filled with joy, laughter, and a touch of magic. Imagine a stunning outdoor setting, complete with plush blankets, stylish decor, and a beautifully arranged table ready to host your perfect birthday celebration.",
      "Surrounded by loved ones, you can relax, indulge in your favorite treats, and soak in the beauty of the moment. We handle every detail, allowing you to simply enjoy the day and create unforgettable memories.",
      "Make your birthday truly extraordinary with a Dream Birthday Picnic —an experience that's as unique and special as you are."
    ],
    amenties: [
      "Picnic for 2-6 , time 2,5-3 hrs",
      "Boho picnic table",
      "Floral arrangements",
      "Aesthetic decor (flowers, pillows, candles)",
      "Speaker",
      "Sparkling water, juice, coffee, tea (optional)",
      "Fruit plate, cheese plate, custom charcuterie, birthday cake",
    ],
    startingAt: "699$",
    image1Url: "/services/birthday/image1.png",
    image2Url: "/services/birthday/image2.png",
    image3Url: "/services/birthday/image3.png",
    imageMainUrl: "/services/birthday/imageMain.png"

  },
  {
    id: "5",
    name: "Dream Beach Date Picnic",
    description: [
      "Discover the ultimate romantic escape with our Dream Beach Date Picnic, set on one of Hawaii’s most breathtaking beaches. Imagine a secluded spot where the golden sands and turquoise waters provide a serene, private haven just for you and your loved one. As the sun sets, painting the sky in brilliant colors, you’ll be enveloped in an atmosphere of pure romance.",
      "Every detail is thoughtfully designed to create an unforgettable moment, making this more than just a date—it’s a memory you’ll cherish forever. Whether celebrating a special occasion or simply enjoying each other’s company, our Dream Beach Date Picnic offers the perfect setting for a love story written in the sands of paradise."
    ],
    amenties: [
      "Picnic for 2, time 2,5 hrs",
      "Boho picnic table",
      "Aesthetic decor (flowers, pillows, candles)",
      "Sparkling water, juice, coffee, tea (optional)",
      "Fruit plate, cheese plate, custom charcuterie",
    ],
    startingAt: "449$",
    image1Url: "/services/beach-date/image1.png",
    image2Url: "/services/beach-date/image2.png",
    image3Url: "/services/beach-date/image3.png",
    imageMainUrl: "/services/beach-date/imageMain.png"
  },
]